// =========================================/
// Documentation -> Tabs
// =========================================/

.page-admin {
	.page-admin-main {
		.admin-documentation {
			.search {
				@include flex-container(space-between,center);
				width: 100%;
				padding-left: 80px;
				.tabs {
					@include flex-container(flex-start,center);
					p {
						padding: 5px 10px;
						border-radius: 5px;
						@include transition(.05s);
						margin: 0px 10px 0px 0px;
						cursor: pointer;
						&:hover {
							background-color: #f2f2f2;
						}
					}
					p.active {
						background-color: $admin-color-white !important;
						box-shadow: 1px 1px 1px #d3d3d3;
					}
				}
				input {
					font-size: .9em;
					padding: 8px;
					width: 250px;
				}
			}
		}
	}
}

// =========================================/
// Documentation -> Posts
// =========================================/

.page-admin {
	.page-admin-main {
		.admin-documentation {
			.posts {
				width: 100%;
				.no-results {
					margin: 50px auto;
					width: 95%;
					max-width: 600px;
					text-align: center;
					img {
						margin-bottom: 20px;
					}
					p {

					}
				}
				.post {
					@include flex-container(space-between,center);
					margin: 20px 0px;
					p.number {
						font-size: 2em;
						line-height: 1em;
						font-weight: 600;
						width: 80px;
						text-align: center;
						background-color: #d3d3d3;
					  color: transparent;
					  text-shadow: 2px 2px 3px rgba(255,255,255,0.5);
					  -webkit-background-clip: text;
						-moz-background-clip: text;
						background-clip: text;
					}
					.body {
						background-color: $admin-color-white;
						box-shadow: 1px 1px 1px #d3d3d3;
						border-radius: 5px;
						padding: 20px;
						width: calc(100% - 80px);
						h2 {
							font-size: 1.3em;
							font-weight: 500;
							margin-bottom: 20px;
						}
						p {
							font-size: .87em;
							a {
								font-size: unset;
							}
						}
						ul {
							list-style-type: disc;
							margin: 0px 0px 20px 20px;
						}
						li {
							font-size: .87em;
							a {
								font-size: unset;
							}
						}
						a {
							font-size: .87em;
							color: $admin-color-secondary;
							font-weight: 600;
						}
						img {
							margin-bottom: 20px;
							max-width: 100%;
						}
						*:last-child {
							margin-bottom: 0px;
						}
						strong {
							font-weight: 600;
						}
						em {
							font-style: italic;
						}
						u {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}