// =========================================/
// Header
// =========================================/

.page-admin {
	.admin-header {
		background-color: $admin-color-secondary-dark;
		padding: 4px 0px 3px;
		box-shadow: 0px 0px 0px 2px #f9f9f9, 0px 0px 0px 4px $admin-color-secondary-dark;
		.container {
			@include flex-container(space-between,center);
			@include global-width;
		}
		.section {
			@include flex-container(flex-start,center);
			a {
				color: #f2f2f2;
				font-size: .95em;
				@include mq(m) {
					display: none;
				}
				i {
					color: $admin-color-secondary;
					margin-right: 5px;
				}
			}
			p {
				margin: 0px;
				color: #f2f2f2;
				font-size: .75em;
				line-height: 1em;
				@include mq(m) {
					display: none;
				}
				a {
					font-size: 1em;
				}
				span.emoji {
					margin-left: 5px;
					font-size: 1.4em;
					vertical-align: middle;
				}
			}
			span.divider {
				margin: 0px 10px;
				color: $color-white;
				@include mq(m) {
					display: none;
				}
			}
			.breadcrumbs {
				margin: 0px;
				@include mq(m) {
					display: inline-block;
				}
				a {
					@include mq(m) {
						display: inline-block;
					}
				}
				.bc-active {
					margin: 0px;
				}
			}
			.dark-mode {
				@include flex-container(center,center);
				margin-left: 20px;
				@include mq(l) {
					display: none;
				}
				p {
					margin: 0px;
					height: 25px;
					width: 28px;
					@include flex-container(center,center);
					border-radius: 4px 0px 0px 4px;
					border: 1px solid rgba($admin-color-secondary,.5);
					cursor: pointer;
					svg {
						height: 12px;
						fill: $admin-color-white;
					}
				}
				p ~ p {
					border-radius: 0px 4px 4px 0px;
				}
				p.active {
					background-color: $admin-color-secondary;
				}
			}
		}
		.section ~ .section {
			a {
				margin-left: 20px;
				@include mq(l) {
					display: none;
				}
			}
			a.signout-link {
				@include mq(l) {
					display: inline-block;
				}
			}
		}
	}
}