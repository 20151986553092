// =========================================/
// Queries
// =========================================/

.admin-vert-list {
	.avl-top {
		@include flex-container(space-between,center);
		width: 100%;
		.avl-select-header {
			@include flex-container(space-between,center);
			padding-bottom: 12px;
			margin-bottom: 5px;
			select {
				font-size: .9em;
				padding: 8px;
				width: 500px;
			}
			a {
				margin-left: 20px;
			}
		}
		.avl-search-filter {
			padding-bottom: 20px;
			input[type=text] {
				font-size: .8em;
				padding: 6px;
				margin-right: 20px;
			}
			label {
				font-weight: 400;
				input {
					margin-left: 5px;
				}
			}
		}
	}
	.avl-post {
		padding: 0px;
		.query {
			p {
				color: $admin-color-black !important;
			}
			.query-header {
				@include flex-container(space-between,center);
				cursor: pointer;
				p {
					margin: 0px;
				}
			}
			.query-fields {
				height: 0px;
				overflow: hidden;
				p {
					font-weight: 600;
					border-bottom: 1px solid #f2f2f2;
					padding-bottom: 5px;
					margin-bottom: 8px;
					span {
						font-weight: 400;
						margin-left: 10px;
					}
					margin-top: -1px;
				}
				.actions {
					margin-top: 25px;
					label {
						font-weight: 400;
						margin-right: 15px;
						input {
							margin-left: 5px;
						}
					}
				}
			}
			.query-fields.active {
				height: auto;
				padding-top: 20px;
			}
		}
	}
}