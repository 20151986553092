// =========================================/
// Media
// =========================================/

.admin-media-container {
	.image-container {
		@include flex-container;
		.acbic-single {
			width: calc(25% - 5px);
			height: 200px;
			margin-bottom: 10px;
			position: relative;
			background-position: center;
			background-size: 90%;
			background-repeat: no-repeat;
			border: 2px solid #f2f2f2;
			cursor: pointer;
			border-radius: 3px;
			@include admin-mq(l) {
				width: calc(33% - 5px);
			}
			@include admin-mq(m) {
				width: calc(50% - 5px);
			}
			@include admin-mq(s) {
				width: 100%;
				height: 300px;
			}
			&:hover {
				border: 2px solid $admin-color-secondary;
			}
			.copy-area {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			.delete {
				position: absolute;
				top: 5px;
				right: 5px;
				z-index: 1;
				&:hover {
					color: $admin-color-primary;
				}
			}
			a.download-image {
				z-index: 1;
				color: #222;
				display: inline-block;
				margin: 5px;
				font-size: .9em;
				position: relative;
				width: max-content;
				&:hover {
					color: $admin-color-primary;
				}
				i {

				}
			}
		}
	}
	.image-container-grid-large {
		.acbic-single {
			width: calc(50% - 5px);
			height: 300px;
			@include mq(m) {
				width: 100%;
			}
		}
	}
	.image-copied-confirmation {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		@include flex-container(center,center);
		background-color: rgba(0,0,0,.6);
		@include transition(0.5s);
		visibility: hidden;
		opacity: 0;
		z-index: 5;
		p {
			color: #fff;
			font-size: 2em;
			margin: 0px;
		}
	}
	.image-copied-confirmation-active {
		visibility: visible;
		opacity: 1;
	}
}
.media-file-name {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 3px;
	text-align: center;
	background-color: rgba(255,255,255,.7);
	margin: 0px !important;
	white-space: nowrap;
	overflow: auto;
	font-size: .75em !important;
	color: unset !important;
	font-style: unset !important;
}

// =========================================/
// Media -> List -> Icons
// =========================================/

.media-icon {
	@include overlay;
	@include flex-container(center,center);
	padding-bottom: 28px;
	i {
		font-size: 5em;
		opacity: .1;
		position: unset !important;
		color: #222 !important;
	}
}

// =========================================/
// Media -> Image Search
// =========================================/

.image-search {
	@include flex-container(space-between,center);
	margin-bottom: 10px;
	input {
		width: 50%;
		max-width: 300px;
		font-size: .9em;
		padding: 5px;
		@include admin-mq(m) {
			width: 100%;
		}
	}
	.actions {
		@include flex-container(flex-end,center);
		@include admin-mq(m) {
			display: none;
		}
		i {
			margin-left: 10px;
	    cursor: pointer;
	    font-size: 1.2em;
	    padding: 5px 7px;
	    border-radius: 3px;
			&:hover {
				background-color: #d9d9d9;
			}
		}
		i.active {
			color: $color-white;
			background-color: $admin-color-secondary;
		}
	}
}

// =========================================/
// Media -> Alts
// =========================================/

.admin-media-container {
	.image-container {
		@include flex-container;
		.acbic-single-alts {
			margin-bottom: 34px;
			input.alt {
				position: absolute;
				top: 100%;
				left: 0;
				width: 100%;
				font-size: .8em;
			}
		}
	}
}