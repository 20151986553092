// =========================================/
// Login 
// =========================================/

.admin-container {
	padding: 50px 0px;
	@include global-width;
}
.admin-login-container {
	@include admin-white-background(0px, 10vh auto);
	width: 95%;
	max-width: 700px;
	display: block;
	.alc-header {
		@include flex-container;
		border-bottom: 1px solid $admin-color-bg-medium;
		p {
			width: 50%;
			text-align: center;
			font-size: 16px;
			padding: 25px 0px;
			background-color: $admin-color-bg-light;
			cursor: pointer;
			margin: 0px;
		}
		p:nth-child(1){
			border-radius: 5px 0px 0px 0px;
		}
		p:nth-child(2){
			border-radius: 0px 5px 0px 0px;
		}
		.alch-active {
			background-color: $admin-color-white;
			width: 50% !important;
			margin-right: 0% !important;
		}
	}
	.alc-form-container {
		padding: 50px;
		h1 {
			font-weight: 900;
			margin: 0px 0px 20px;
		}
		label {
			display: block;
			font-size: 14px;
		}
		input {
			width: 100%;
		  padding: 8px;
		  border: 1px solid $admin-color-bg-medium;
		  margin: 5px 0px 10px;
		}
		input[type=submit] {
			@include transition;
			width: 150px;
			background-color: $admin-color-secondary;
			color: $admin-color-white;
			border: 0px;
			border-radius: 3px;
			display: inline-block;
			margin: 10px 10px 0px 0px;
			font-size: .9em;
			&:hover {
				background-color: $admin-color-secondary-dark;
			}
			p:last-child {
				margin-bottom: 0px;
			}
		}
		a {
			font-size: 13px;
		}
		.buttons {
			a {
				font-size: .9em;
				display: inline-block;
				margin-left: 20px;
			}
		}
	}
	h1 {
		font-size: 22px;
		margin: 0px 0px 30px;
	}
	.alc-sign-out {
		text-align: center;
		font-size: 1.2em;
		margin: 0px;
		padding: 20px 0px;
	}
}

// =========================================/
// Password Reset 
// =========================================/

.reset-password-container {
	@include admin-white-background(30px, 10vh auto);
	max-width: 700px;
	h1 {
		font-size: 22px;
		margin: 0px 0px 30px;
	}
	p:last-child {
		margin-bottom: 0px;
	}
	p.confirmation-message {
		color: #a9a9a9;
		font-style: italic;
	}
	form {
		input {
			width: 100%;
			padding: 8px;
			border: 1px solid $admin-color-bg-medium;
			font-size: .9em;
		}
		input[type=submit] {
			@include transition;
			width: 150px;
			background-color: $admin-color-secondary;
			color: $admin-color-white;
			border: 0px;
			border-radius: 3px;
			display: inline-block;
			margin: 15px auto 0px;
			font-size: .9em;
			padding: 8px;
			&:hover {
				background-color: $admin-color-secondary-dark;
			}
			p:last-child {
				margin-bottom: 0px;
			}
		}
	}
}