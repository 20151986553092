// =========================================/
// Shop - Tabs
// =========================================/

.page-admin {
	.page-admin-shop {
		.block {
			padding: 30px;
			margin-bottom: 25px;
			*:last-child {
				margin-bottom: 0px;
			}
			.tabs {
				justify-content: flex-start;
				p {
					flex-grow: unset;
				}
			}
		}
	}
}

// =========================================/
// Shop - Orders
// =========================================/

.orders {
	.actions {
		@include flex-container(flex-end,center);
		padding: 20px;
		div {
			@include flex-container;
			label {
				font-family: $admin-body-font;
				font-size: .8em;
				font-weight: 500;
				line-height: 1em;
				cursor: pointer;
				input {
					margin-left: 8px;
				}
			}
			a {
				line-height: 1.2em;
				font-size: .95em;
				margin: 0px 0px 0px 15px;
				display: block;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	.order {
		background-color: #fff;
		border-bottom: 1px solid #d3d3d3;
		position: relative;
		width: 100%;
		.top {
			@include flex-container(space-between,center);
			cursor: pointer;
			padding: 20px;
			&:hover {
				background-color: #f2f2f2;
			}
			.details {
				@include flex-container(flex-start,center);
				width: calc(100% - 30px);
				p {
					margin: 0px 15px 0px 0px;
				}
			}
			.status {
				width: 30px;
				svg {
					width: 30px;
				}
				.warn {
					fill: red;
					display: none;
				}
				.done {
					fill: green;
					display: none;
				}
			}
			.status.pending {
				.warn {
					display: block;
				}
			}
			.status.complete {
				.done {
					display: block;
				}
			}
		}
		.main {
			@include flex-container;
			display: none;
			padding: 20px;
			p {
				font-weight: 600;
				span {
					font-weight: 400;
				}
			}
			.person {
				width: 49%;
				label {
					font-weight: 400;
					cursor: pointer;
					input {
						margin-left: 8px;
					}
				}
			}
			.product {
				width: 49%;
				.item {
					border-bottom: 1px solid #f2f2f2;
					margin-bottom: 1em;
					p ~ p {
						font-weight: 400;
					}
				}
			}
		}
	}
	.order.active {
		border-bottom: 2px solid #333;
		.top {
			border-bottom: 1px solid #f2f2f2;
		}
		.main {
			display: flex;
		}
	}
}

// =========================================/
// Shop - Products
// =========================================/

.page-admin {
	.page-admin-shop {
		.products {
			.variation, .product {
				@include flex-container(flex-start,center);
				width: 100%;
				input {
					width: 100px;
					text-align: center;
					font-size: .9em;
					padding: 5px;
				}
				input:nth-child(1) {
					background-color: #f9f9f9;
				}
				input:nth-child(1), input:nth-child(2) {
					width: calc((100% - 500px) / 2);
					text-align: left;
				}
			}
			.product.labels {
				p {
					font-weight: 600;
					width: 100px;
					text-align: center;
					font-size: .9em;
				}
				p:nth-child(1), p:nth-child(2) {
					width: calc((100% - 500px) / 2);
					text-align: left;
					padding: 0px 0px 0px 5px;
				}
			}
			.product.updates {
				border-top: 2px solid #333;
				align-items: flex-start;
				.input {
					font-weight: 600;
					width: 100px;
					text-align: center;
					input {
						width: 100%;
						font-size: .9em;
						background-color: $admin-color-white;
					}
					.actions {
						span {
							opacity: 0;
							visibility: hidden;
							@include transition;
							display: block;
							width: max-content;
							cursor: pointer;
							margin: auto;
							padding: 5px 8px;
							border-radius: 2px;
							background-color: #f2f2f2;
							font-weight: 300;
							font-size: .9em;
							margin-top: 5px;
							&:hover {
								background-color: $admin-color-primary;
								color: $admin-color-white;
							}
						}
					}
				}
				.input.active {
					.actions {
						span {
							opacity: 1;
							visibility: visible;
						}
					}
				}
				p, .input:nth-child(2) {
					width: calc((100% - 500px) / 2);
					text-align: left;
				}
				p {
					padding: 0px 0px 0px 5px;
					margin: 3px 0px 0px;
					font-style: italic;
				}
			}
		}
	}
}