* {
	box-sizing: border-box;
}
html {
	scroll-behavior: smooth;
	@include animation(pageFade);
}
body {
	overflow-x: hidden;
}
@keyframes pageFade {
	0% {opacity: 0;}
	100% {opacity: 1;}
}
body,
textarea,
input {
	font-family: $font-stack-body;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 500;
	font-family: $font-stack-header;
}
.page {
	min-height: 100vh;
}
a {
	color: $color-primary;
	text-decoration: none;
}
a:hover {
	color: $color-secondary;
	text-decoration: none;
}
.ql-size-huge {
	font-size: 24px;
	line-height: 32px;
	font-weight: 500;
	margin: 20px 0px 10px;
	display: inline-block;
}
.ql-size-large {
	font-size: 20px;
	line-height: 28px;
	font-weight: 500;
	margin: 20px 0px 10px;
	display: inline-block;
}
.breadcrumbs {
	padding: 10px 0px;
	font-size: 14px;
}
.breadcrumbs span {
	margin: 0px 5px;
}
p {
	font-size: 14px;
	line-height: 22px;
	margin-bottom: 16px;
}
li {
	font-size: 14px;
  line-height: 22px;
  margin-bottom: 5px;
}

// =========================================/
// Form Defaults
// =========================================/

input, textarea, select {
	border: 1px solid #d3d3d3;
}

// =========================================/
// Loading
// =========================================/

.loading {
	height: 80vh;
	@include flex-container(center,center);
}

// =========================================/
// Antispam
// =========================================/

.antispam {
	display: none;
}

// =========================================/
// Alerts
// =========================================/

.alert {
	position: relative;
}
.alert-close {
	position: absolute;
	top: 0;
	right: 0;
	margin: 12px;
	opacity: .5;
	font-size: 22px;
}
.alert-close:hover {
	cursor: pointer;
	opacity: 1;
}
.alert-center-container {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0,.2);
	display: flex;
	justify-content: center;
	align-items: center;
}
.alert-center {
  border-radius: 5px;
  padding: 40px;
  box-shadow: 1px 1px 1px $color-shade-dark;
  min-width: 400px;
  min-height: 250px;
  max-width: 90vw;
  max-height: 90vh;
}
.alert-top {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 30px;
}
.alert-bottom {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 30px;
}

// =========================================/
// Center Block
// =========================================/

.center-block {
	p {
		text-align: center;
		font-size: 1.5em;
		padding: 0px 20px;
		margin-top: 30vh;
	}
}

//===================================
//= Loading
//===================================

.loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	@include flex-container(center,center);
	background-color: rgba(255,255,255,.7);
	z-index: 100;
	.outer {
		width: 95%;
		max-width: 500px;
		fill: $admin-color-primary;
		@include animation(spin, 2s, linear, infinite);
	}
	.inner-container {
		@include overlay;
		@include flex-container(center,center);
		z-index: 1;
		.inner {
			width: 95%;
			max-width: 300px;
			fill: $admin-color-secondary;
			@include animation(spinInner, 1.5s, linear, infinite);
		}
	}
}
@keyframes spin {
	0% {transform: rotate(0deg);}
	100% {transform: rotate(360deg);}
}

@keyframes spinInner {
	0% {transform: rotate(0deg);}
	100% {transform: rotate(-360deg);}
}