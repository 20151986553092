// =========================================/
// Config - Dashboard Blocks
// =========================================/

.page-admin {
	.page-admin-main {
		.admin-config {
			.block {
				padding: 20px;
				margin-bottom: 25px;
				p:last-child {
					margin-bottom: 0px;
					font-style: italic;
					color: #a9a9a9;
				}
			}
			.dashboard-blocks {
				.item {
					background-color: #fff;
					border-bottom: 2px solid #333;
					padding: 20px;
					cursor: move;
					position: relative;
					width: 100%;
					i {
						font-size: 1em;
						top: 8px;
						right: 0;
						color: #d3d3d3;
						position: absolute;
						padding: 0px 5px;
						cursor: pointer;
						&:hover {
							color: gray;
						}
					}
					.details {
						padding: 0px 30px 0px 0px;
						p {
							display: inline-block;
							margin: 0px;
						}
						label {
							font-weight: 600;
							font-size: 1em;
							@include admin-mq(m) {
								@include flex-container(space-between,center);
								width: 100%;
								margin-bottom: 10px;
							}
						}
						input {
							font-size: .9em;
							padding: 5px;
							margin: 0px 15px 0px 5px;
							@include admin-mq(m) {
								width: calc(100% - 150px);
								margin-right: 0px;
							}
						}
						.desc {
							width: 100%;
							margin-top: 10px;
							input {
								width: calc(100% - 200px);
								@include admin-mq(m) {
									width: calc(100% - 150px);
								}
							}
						}
					}
				}
			}
			.buttons-bottom {
				position: relative;
				width: 100%;
				.add-item {
					p {
						@include admin-mq(l) {
							margin: 0px;
							width: 100%;
						}
					}
				}
				div:nth-child(2) {
					@include admin-mq(m) {
						width: 100%;
						margin-top: 20px;
					}
					p {
						@include admin-mq(l) {
							margin: 0px 15px 0px 0px;
						}
					}
				}
			}
		}
	}
}

// =========================================/
// Config - Post Types
// =========================================/

.post-types {
	.item {
		background-color: #fff;
		border-bottom: 2px solid #333;
		padding: 20px;
		position: relative;
		width: 100%;
		i {
			font-size: 1em;
			top: 8px;
			right: 0;
			color: #d3d3d3;
			position: absolute;
			padding: 0px 5px;
			cursor: pointer;
			&:hover {
				color: gray;
			}
		}
		.details {
			padding: 0px 30px 0px 0px;
			p {
				display: inline-block;
				margin: 0px;
			}
			label {
				font-weight: 600;
				font-size: 1em;
				@include admin-mq(m) {
					@include flex-container(space-between,center);
					width: 100%;
					margin-bottom: 10px;
				}
			}
			input {
				font-size: .9em;
				padding: 5px;
				margin: 0px 15px 0px 5px;
				@include admin-mq(m) {
					width: calc(100% - 150px);
					margin-right: 0px;
				}
			}
			.desc {
				width: 100%;
				margin-top: 10px;
				textarea {
					margin-left: 5px;
					width: calc(100% - 200px);
					border: 1px solid #d3d3d3;
					font-size: .9em;
					padding: 5px;
					height: 100px;
					@include admin-mq(m) {
						width: calc(100% - 150px);
					}
				}
			}
		}
	}
}

// =========================================/
// Config - Client Details
// =========================================/

.client-details {
	margin-bottom: 25px;
	background-color: $color-white;
	padding: 20px;
	@include flex-container;
	.item {
		width: 48%;
		@include flex-container(space-between,center);
		margin-bottom: 10px;
		@include admin-mq(m) {
			width: 100%;
		}
		label {
			font-size: 1em;
			width: 165px;
		}
		input {
			font-size: .9em;
			padding: 3px;
			width: calc(100% - 170px);
		}
	}
}

// =========================================/
// Config - Settings
// =========================================/

.settings {
	.setting {
		background-color: #fff;
		padding: 20px;
		position: relative;
		width: 100%;
		margin-bottom: 25px;
		border-radius: 5px;
		box-shadow: 0px 2px 2px #d3d3d3;
		h2 {
			margin-bottom: .5em;
			font-size: 1.2em;
			font-weight: 500;
		}
		p {
			font-size: .8em;
			font-style: italic;
			color: #a9a9a9;
			line-height: 1.5em;
			margin-bottom: 1em;
		}
		textarea {
			width: 100%;
			padding: 8px;
			border: 1px solid #d3d3d3;
			font-size: .85em;
			height: 100px;
			font-family: 'Courier New', monospace;
		}
	}
}