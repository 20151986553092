// =========================================/
// Dashboard
// =========================================/

.admin-dashboard {
	@include flex-container;
	@include admin-mq(m) {
		justify-content: center;
	}
	padding-bottom: 15px;
	.ad-single {
		@include admin-white-background(20px, 15px 0px);
		width: calc(33% - 15px);
		position: relative;
		color: $color-black !important;
		text-decoration: none !important;
		@include transition(.2s);
		@include admin-mq(l) {
			width: calc(50% - 15px);
		}
		@include admin-mq(m) {
			width: 100%;
		}
		h2 {
			margin: 0px 0px 12px;
		  font-size: 22px;
		  line-height: 28px;
		}
		p {
			color: #a9a9a9;
			font-style: italic;
		}
		p:last-child {
			margin-bottom: 0px;
		}
		form {
			font-size: 14px;
		}
		.ads-link {
			@include transition;
			width: 100px;
		  border: 2px solid $admin-color-bg-medium;
		  padding: 5px;
		  display: block;
		  text-align: center;
		  color: $admin-color-black;
		  margin: 20px 0px 0px 0px;
		  border-radius: 3px;
			font-size: 1em;
			&:hover {
				background-color: $admin-color-bg;
				border: 2px solid $admin-color-bg;
				color: $admin-color-white;
			}
			i {
				padding-left: 5px;
			}
		}
		.ads-ma-logo {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 100px;
			margin: 20px;
		}
	}
	a.ad-single {
		&:hover {
			box-shadow: 2px 2px 5px #a9a9a9;
		}
	}
}

// =========================================/
// Dashboard -> Search
// =========================================/

.admin-dashboard {
	.search-filter {
		width: 100%;
		@include flex-container(space-between,center);
		margin-bottom: 15px;
		input {
			font-size: .9em;
			width: calc(33% - 15px);
			padding: 8px;
			@include admin-mq(l) {
				width: calc(50% - 15px);
			}
			@include admin-mq(m) {
				width: 100%;
				margin-bottom: 25px;
			}
		}
		.select {
			@include flex-container(space-between,center);
			width: calc(33% - 15px);
			@include admin-mq(l) {
				width: calc(50% - 15px);
			}
			@include admin-mq(m) {
				width: 100%;
			}
			select {
				font-size: .9em;
				padding: 7px;
				width: calc(100% - 96px);
				color: #888;
				@include admin-mq(m) {
					width: calc(100% - 140px);
				}
			}
			a {
				color: #888;
				margin-left: 20px;
				cursor: default;
				&:hover {
					text-decoration: none;
				}
				@include admin-mq(m) {
					font-size: 1.1em;
					padding: 5px 10px;
				}
			}
			a.active {
				color: $admin-color-primary;
				cursor: pointer;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

// =========================================/
// Dashboard -> Search Results
// =========================================/

.admin-dashboard {
	.results {
		width: 100%;
		@include flex-container;
		.res {
			display: block;
			background-color: #fff;
			box-shadow: 1px 1px 1px #d3d3d3;
			border-radius: 5px;
			padding: 20px;
			margin: 15px 0px;
			width: 32%;
			position: relative;
			color: #333 !important;
			text-decoration: none !important;
			transition: all 0.2s;
			h2 {
				font-family: $admin-header-font;
				font-size: 22px;
				line-height: 28px;
				font-weight: 500;
			}
		}
	}
}