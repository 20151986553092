// =========================================/
// Shortcodes
// =========================================/

.acb-shortcodes {
	padding: 20px 20px 0px;
	.acbs-header {
		cursor: pointer;
		width: max-content;
		svg {
			width: 12px;
			margin: 0px 0px -1px 15px;
		}
		&:hover {
			text-decoration: underline;
		}
	}
	.acb-sc-list {
		.sc {
			border-top: 2px solid;
			padding-top: 15px;
			margin-top: 15px;
			p {
				color: #777 !important;
				font-style: unset !important;
			}
			.sc-label {
				font-weight: 600;
				font-size: 1.1em;
			}
			input {
				font-size: .8em !important;
				padding: 8px;
				margin-bottom: 1em;
				max-width: 415px;
				border: 1px solid #d3d3d3;
			}
			.sc-desc {
				font-size: .8em;
				font-style: italic;
				color: #a9a9a9;
			}
			hr {
				border: 0px;
				border-top: 1px solid #d3d3d3;
				width: 40%;
				margin: 5px auto 1em 0px;
			}
			.options {
				margin-left: 15px;
				p {
					font-size: .8em;
					margin-bottom: .5em;
					strong {
						font-weight: 600;
					}
					em {
						font-style: italic;
					}
					a {

					}
				}	
			}
		}
	}
	.acbs-rules {
		font-style: italic;
		margin-bottom: 0px;
		font-size: .9em;
		margin-top: 1.5em;
	}
}