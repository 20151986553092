// =========================================/
// Temporary Pages (creating, deleting, no access)
// =========================================/

.page-admin {
	.temporary-page {
		margin: 50px auto;
		width: 95%;
		max-width: 600px;
		text-align: center;
		h1 {
			font-size: 3em;
			color: $admin-color-secondary;
			margin-bottom: 30px;
		}
		p {
			font-size: 1.2em;
			line-height: 1.4em;
			margin-bottom: 30px;
		}
	}
}