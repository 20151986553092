// =========================================/
// Dark Mode -> Global
// =========================================/

.page-admin-dark-mode {
	background-color: $admin-color-black;
	.admin-header {
		background-color: $admin-color-secondary;
		box-shadow: 0px 0px 0px 2px $admin-color-black, 0px 0px 0px 4px $admin-color-secondary;
		.section {
			a {
				i {
					color: $admin-color-secondary-dark;
					opacity: .5;
				}
			}
			.dark-mode {
				p {
					border: 1px solid rgba($admin-color-secondary-dark,.2);
				}
				p.active {
					background-color: $admin-color-secondary-dark;
				}
			}
		}
	}
	.admin-footer {
		background-color: #111;
		box-shadow: 0px 0px 0px 5px $admin-color-black, 0px 0px 0px 10px #111;
		img {
			filter: brightness(0) invert(1);
		}
	}
	.page-admin-main {
		p.no-posts {
			color: #d3d3d3;
		}
		p.select-images, p.select-image {
			color: $admin-color-white !important;
		}
	}
	.loading {
		background-color: rgba($admin-color-black,.8);
	}
}
.loading.loading-dark-mode {
	background-color: rgba($admin-color-black,.9);
}

// =========================================/
// Dark Mode -> Login
// =========================================/

.page-admin-dark-mode {
	.page-admin-main {
		.admin-login-container {
			background-color: #444;
			box-shadow: 1px 1px 1px #666;
			.alc-header {
				border-bottom: 1px solid #666;
				p.alch-active {
					background-color: #666;
				}
				p {
					background-color: #444;
					color: $admin-color-white;
				}
			}
			.alc-form-container {
				form {
					input[type='email'], input[type='password'], input[type='text'] {
						background-color: $admin-color-black;
						border: 1px solid #777;
						color: $admin-color-white;
						-webkit-transition-delay: 99999s;
					}
					input[type=submit] {
						&:hover {
							background-color: darken($admin-color-secondary,10%);
						}
					}
					label {
						color: #d3d3d3;
					}
					p {
						color: $admin-color-white;
					}
				}
				.buttons {
					a {
						color: #d3d3d3;
					}
				}
				p.alc-sign-out {
					color: $admin-color-white;
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> Reset Password
// =========================================/

.page-admin-dark-mode {
	.page-admin-main {
		.reset-password-container {
			background-color: #444;
			box-shadow: 1px 1px 1px #666;
			h1 {
				color: $admin-color-white;
			}
			p {
				color: #d3d3d3;
			}
			form {
				input[type='email'], input[type='password'] {
					background-color: $admin-color-black;
					border: 1px solid #777;
					color: $admin-color-white;
					-webkit-transition-delay: 99999s;
				}
				input[type=submit] {
					&:hover {
						background-color: darken($admin-color-secondary,10%);
					}
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> Dashboard
// =========================================/

.page-admin-dark-mode {
	.admin-dashboard {
		.ad-single {
			background-color: #444;
			box-shadow: 1px 1px 1px #666;
			h2 {
				color: $admin-color-white;
			}
			p {
				color: #d3d3d3;
			}
			&:hover {
				box-shadow: 2px 2px 2px #666;
			}
		}
		.search-filter {
			input {
				background-color: $admin-color-black;
				border: 1px solid #777;
				color: $admin-color-white;
			}
			.select {
				select {
					background-color: $admin-color-black;
					color: #666;
					border: 1px solid #777;
				}
				select.active {
					color: $admin-color-white;
				}
			}
		}
		.results {
			.res {
				background-color: #444;
				box-shadow: 1px 1px 1px #666;
				h2 {
					color: $admin-color-white;
				}
				&:hover {
					box-shadow: 2px 2px 2px #666;
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> List Pages
// =========================================/

.page-admin-dark-mode {
	.admin-content-listing {
		.acl-header {
			.left {
				h1 {
					color: $admin-color-white;
				}
				a {
					border: 2px solid #777;
					color: #777;
					&:hover {
						color: #f2f2f2;
						border: 2px solid #f2f2f2;
					}
				}
			}
			input {
				background-color: $admin-color-black;
				border: 1px solid #777;
				color: $admin-color-white;
			}
		}
		.atb-single {
			background-color: #444;
			box-shadow: 1px 1px 1px #666;
			h2 {
				color: $admin-color-white;
			}
			p {
				color: #d3d3d3;
			}
			&:hover {
				box-shadow: 2px 2px 2px #666;
			}
		}
	}
}

// =========================================/
// Dark Mode -> Content Pages
// =========================================/

.page-admin-dark-mode {
	.page-admin-main {
		.admin-content {
			.atbs-header {
				h2 {
					color: $admin-color-white;
				}
				.atbs-header-sub {
					.left {
						input {
							background-color: $admin-color-black;
							border: 1px solid #777;
							color: $admin-color-white;
						}
						svg {
							&:hover {
								background-color: #444;
							}
						}
						svg.active {
							background-color: $admin-color-secondary;
						}
					}
					p {
						color: #888;
					}
				}
			}
			form {
				.ac-block {
					background-color: #444;
					box-shadow: 1px 1px 1px #666;
					border: 3px solid #444;
					h2 {
						color: $admin-color-white;
						.title-helper {
							.title-content {
								background-color: #222;
								color: #d3d3d3;
							}
						}
					}
					p {
						color: #d3d3d3;
					}
					&:hover {
						box-shadow: 2px 2px 2px #666;
					}
					input, select {
						background-color: $admin-color-black;
						border: 1px solid #777;
						color: $admin-color-white;
					}
					.fa-times {
						&:hover {
							color: $admin-color-white;
						}
					}
				}
				.ac-block.active {
					box-shadow: 1px 1px 1px #666 !important;
				}
				.ac-block.block-focused {
					@include animation(blockFocusedDark,2s);
				}
				.buttons-bottom {
					background-color: #444;
					border-top: 1px solid #777;
					div {
						p {
							color: #d3d3d3;
							input {
								background-color: $admin-color-black;
								border: 1px solid #777;
								color: $admin-color-white;
							}
						}
						p.save {
							color: $admin-color-white;
						}
						p.top {
							background-color: #777;
							svg {
								fill: $admin-color-white;
							}
						}
					}
				}
			}
		}
	}
}
@keyframes blockFocusedDark {
	0% { border: 3px solid $admin-color-primary; }
	100% { border: 3px solid #444; }
}

// =========================================/
// Dark Mode -> Content Pages -> Image
// =========================================/

.page-admin-dark-mode {
	.acb-image, .acb-images {
		.acbi-active {
			background-color: $admin-color-black;
			border: 2px solid $admin-color-black;
			.media-icon {
				i {
					opacity: .5;
				}
			}
			p.media-file-name {
				background-color: #444;
				color: #f2f2f2 !important;
			}
		}
		.acbi-container {
			.acbic-new {
				background-color: $admin-color-black;
				border: 2px dashed #444;
			}
			.image-search {
				i {
					&:hover {
						background-color: #555;
					}
				}
				i.active {
					background-color: $admin-color-secondary;
				}
			}
			.image-container {
				.acbic-single {
					background-color: $admin-color-black;
					border: 2px solid $admin-color-black;
					p.media-file-name {
						background-color: #444;
						color: #f2f2f2 !important;
					}
					.media-icon {
						i {
							opacity: .4;
						}
					}
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> Content Pages -> Image
// =========================================/

.page-admin-dark-mode {
	.admin-content {
		.ac-block {
			.atbs-editor {
				background-color: $admin-color-black;
				.ql-toolbar {
					.ql-bold, .ql-italic, .ql-underline {
						svg {
							path, line, rect {
								stroke: #d3d3d3;
							}
						}
					}
					.ql-strike {
						line { 
							stroke: #d3d3d3;
						}
						path {
							fill: #d3d3d3;
						}
					}
					.ql-blockquote {
						.ql-stroke.ql-fill {
							fill: #d3d3d3;
							stroke: #d3d3d3;
						}
					}
					.ql-picker-label, .ql-background {
						svg {
							line, polyline {
								stroke: #d3d3d3;
							}
							g {
								fill: #666;
							}
						}
					}
					.ql-list, .ql-indent {
						line, polyline {
							stroke: #d3d3d3;
						}
						path {
							fill: #d3d3d3;
							stroke: #d3d3d3;
						}
					}
					.ql-script {
						path {
							fill: #d3d3d3;
						}
					}
					.ql-link {
						line, path {
							stroke: #d3d3d3;
						}
					}
					.ql-clean {
						line {
							stroke: #d3d3d3;
						}
						rect {
							fill: #d3d3d3;
						}
					}
					.ql-picker {
						.ql-picker-label {
							color: #d3d3d3;
							svg {
								polygon {
									stroke: #d3d3d3;
								}
							}
						}
						.ql-picker-options {
							background-color: $admin-color-black;
							span {
								color: #d3d3d3;
							}
						}
					}
				}
				.ql-editor {
					color: $admin-color-white;
				}
				.ql-tooltip.ql-editing {
					background-color: #222;
					color: #fff;
					box-shadow: 0px 0px 2px #666;
					border: 0px;
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> Media
// =========================================/

.page-admin-dark-mode {
	.page-admin-main {
		.admin-media-container {
			.image-search {
				.actions {
					i {
						color: #d3d3d3;
					}
					i.active {
						color: $admin-color-white;
					}
				}
			}
			.image-container {
				.acbic-single {
					.media-icon {
						i {
							opacity: .4;
						}
					}
					input.alt {
						background-color: $admin-color-black;
						border: 1px solid #777;
						color: $admin-color-white;
					}
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> Content Pages -> List
// =========================================/

.page-admin-dark-mode {
	.admin-content {
		form {
			.ac-block {
				.list {
					p {
						color: #d3d3d3;
						background-color: $admin-color-black;
						box-shadow: 1px 1px 2px #555;
					}
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> Content Pages -> Table
// =========================================/

.page-admin-dark-mode {
	.admin-content {
		.ac-block {
			.table {
				.row {
					border-right: 1px solid #777;
					.column {
						textarea {
							background-color: $admin-color-black;
							border-left: 1px solid #777;
							border-top: 1px solid #777;
							color: $admin-color-white;
						}
					}
				}
				.row:last-child {
					.column {
						textarea {
							border-bottom: 1px solid #777;
						}
					}
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> Content Pages -> Code
// =========================================/

.page-admin-dark-mode {
	.admin-content {
		.ac-block {
			.code-textarea {
				background-color: $admin-color-black;
				border: 1px solid #777;
				color: $admin-color-white;
			}
		}
	}
}

// =========================================/
// Dark Mode -> Content Pages -> Blocks New
// =========================================/

.page-admin-dark-mode {
	.admin-content {
		.ac-block {
			.acb-new-section {
				.list-options {
					p {
						background-color: $admin-color-black;
						color: #d3d3d3;
						&:hover {
							background-color: #555;
						}
					}
				}
				.list-selected {
					.list-sel {
						border: 1px solid #777;
					}
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> Media
// =========================================/

.page-admin-dark-mode {
	.page-admin-main {
		.admin-media-container {
			.acbic-new {
				background-color: $admin-color-black;
				border: 2px dashed #777;
				p {
					color: #d3d3d3;
				}
			}
			.image-search {
				input {
					background-color: $admin-color-black;
					border: 1px solid #777;
					color: $admin-color-white;
				}
				.actions {
					i {
						&:hover {
							background-color: #555;
						}
					}
				}
			}
			.image-container {
				.acbic-single {
					background-color: #444;
					border: 2px solid #444;
					p.media-file-name {
						background-color: #666;
						color: #f2f2f2 !important;
					}
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> Navigation
// =========================================/

.page-admin-dark-mode {
	.page-admin-main {
		.admin-navigation {
			.block {
				background-color: #444;
				box-shadow: 1px 1px 1px #666;
				h1 {
					color: $admin-color-white;
				}
				p {
					color: #d3d3d3;
				}
			}
			.nav {
				.item {
					background-color: #444;
					border-bottom: 1px solid #666;
					i {
						color: #777;
						&:hover {
							color: #d3d3d3;
						}
					}
					.details {
						p {
							color: #d3d3d3;
						}
						label {
							color: #d3d3d3;
						}
						input {
							background-color: $admin-color-black;
							border: 1px solid #777;
							color: $admin-color-white;
						}
					}
				}
			}
			.buttons-bottom {
				background-color: $admin-color-black;
				border-top: 1px solid #666;
				div {
					p {
						color: #d3d3d3;
					}
					p.save {
						color: $admin-color-white;
					}
				}
				div.add-item {
					background-color: $admin-color-black;
					border: 2px dashed #777;
					&:hover {
						border: 2px solid $admin-color-primary;
					}
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> Forms
// =========================================/

.page-admin-dark-mode {
	.page-admin-main {
		.admin-form {
			h1 {
				color: $admin-color-white;
			}
			.form-details-header {
				background-color: #444;
				box-shadow: 1px 1px 1px #666;
				p {
					color: $admin-color-white;
				}
				svg {
					fill: $admin-color-white;
				}
				&:hover {
					background-color: #555;
				}
			}
			.block {
				background-color: #444;
				box-shadow: 1px 1px 1px #666;
				.form-details {
					.detail {
						h2 {
							color: $admin-color-white;
						}
						p {
							color: #d3d3d3;
						}
						input, select {
							background-color: $admin-color-black;
							border: 1px solid #777;
							color: $admin-color-white;
						}
						.atbs-editor {
							background-color: $admin-color-black;
							.ql-toolbar {
								.ql-bold, .ql-italic, .ql-underline {
									svg {
										path, line, rect {
											stroke: #d3d3d3;
										}
									}
								}
								.ql-strike {
									line { 
										stroke: #d3d3d3;
									}
									path {
										fill: #d3d3d3;
									}
								}
								.ql-blockquote {
									.ql-stroke.ql-fill {
										fill: #d3d3d3;
										stroke: #d3d3d3;
									}
								}
								.ql-picker-label, .ql-background {
									svg {
										line, polyline {
											stroke: #d3d3d3;
										}
										g {
											fill: #666;
										}
									}
								}
								.ql-list, .ql-indent {
									line, polyline {
										stroke: #d3d3d3;
									}
									path {
										fill: #d3d3d3;
										stroke: #d3d3d3;
									}
								}
								.ql-script {
									path {
										fill: #d3d3d3;
									}
								}
								.ql-link {
									line, path {
										stroke: #d3d3d3;
									}
								}
								.ql-clean {
									line {
										stroke: #d3d3d3;
									}
									rect {
										fill: #d3d3d3;
									}
								}
								.ql-picker {
									.ql-picker-label {
										color: #d3d3d3;
										svg {
											polygon {
												stroke: #d3d3d3;
											}
										}
									}
									.ql-picker-options {
										background-color: $admin-color-black;
										span {
											color: #d3d3d3;
										}
									}
								}
							}
							.ql-editor {
								color: $admin-color-white;
							}
						}
					}
				}
			}
			.form-inputs {
				background-color: #444;
				box-shadow: 1px 1px 1px #666;
				p.header {
					color: $admin-color-white;
				}
				svg {
					fill: $admin-color-white;
				}
			}
			.form-groups {
				background-color: #333;
				box-shadow: 0px 0px 5px inset #222;
				.group {
					.input {
						label {
							color: #d3d3d3;
						}
						input.label-input {
							border: 0px;
							color: $admin-color-white;
							&:hover {
								background-color: #444;
							}
						}
						input.placeholder-input, textarea.placeholder-input, input.options-input {
							border: 1px solid #777;
							background-color: #333;
						}
						textarea.text-input {
							color: #fff;
							&:hover {
								background-color: #444;
							}
						}
					}
					i {
						color: #777;
					}
				}
				.group-payment {
					.input {
						p {
							color: $admin-color-white;
						}
						label {
							select, input {
								background-color: #333;
								color: $admin-color-white;
								border: 0px;
							}
						}
					}
				}
			}
			.form-buttons {
				p {
					color: #d3d3d3;
				}
			}
			.buttons-bottom {
				background-color: $admin-color-black;
				border-top: 1px solid #666;
				div {
					p {
						color: #d3d3d3;
					}
					p.save {
						color: $admin-color-white;
					}
					p.top {
						background-color: #777;
						svg {
							fill: $admin-color-white;
						}
					}
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> Shop
// =========================================/

.page-admin-dark-mode {
	.page-admin-main {
		.page-admin-shop {
			.block {
				background-color: #444;
				box-shadow: 1px 1px 1px #666;
				h1 {
					color: $admin-color-white;
				}
				p {
					color: #d3d3d3;
				}
				.tabs {
					p {
						color: $admin-color-white;
						&:hover {
							background-color: #555;
						}
					}
				}
				.section {
					h4 {
						color: $admin-color-white;
					}
					p.desc {
						color: #d3d3d3;
					}
					input {
						background-color: $admin-color-black;
						border: 1px solid #777;
						color: $admin-color-white;
					}
				}	
				h3 {
					color: $admin-color-white;
				}
				.atbs-editor {
					background-color: $admin-color-black;
					.ql-toolbar {
						.ql-bold, .ql-italic, .ql-underline {
							svg {
								path, line, rect {
									stroke: #d3d3d3;
								}
							}
						}
						.ql-strike {
							line { 
								stroke: #d3d3d3;
							}
							path {
								fill: #d3d3d3;
							}
						}
						.ql-blockquote {
							.ql-stroke.ql-fill {
								fill: #d3d3d3;
								stroke: #d3d3d3;
							}
						}
						.ql-picker-label, .ql-background {
							svg {
								line, polyline {
									stroke: #d3d3d3;
								}
								g {
									fill: #666;
								}
							}
						}
						.ql-list, .ql-indent {
							line, polyline {
								stroke: #d3d3d3;
							}
							path {
								fill: #d3d3d3;
								stroke: #d3d3d3;
							}
						}
						.ql-script {
							path {
								fill: #d3d3d3;
							}
						}
						.ql-link {
							line, path {
								stroke: #d3d3d3;
							}
						}
						.ql-clean {
							line {
								stroke: #d3d3d3;
							}
							rect {
								fill: #d3d3d3;
							}
						}
						.ql-picker {
							.ql-picker-label {
								color: #d3d3d3;
								svg {
									polygon {
										stroke: #d3d3d3;
									}
								}
							}
							.ql-picker-options {
								background-color: $admin-color-black;
								span {
									color: #d3d3d3;
								}
							}
						}
					}
					.ql-editor {
						color: $admin-color-white;
					}
				}
			}
			.buttons-bottom {
				background-color: $admin-color-black;
				border-top: 1px solid #666;
				div {
					p {
						color: #d3d3d3;
					}
					p.save {
						color: $admin-color-white;
					}
					p.top {
						background-color: #777;
						svg {
							fill: $admin-color-white;
						}
					}
				}
			}
			.orders {
				.actions {
					div {
						label {
							color: #d3d3d3;
						}
						a {
							color: #d3d3d3;
						}
					}
				}
				.order {
					background-color: #444;
					border-bottom: 1px solid #666;
					.top {
						.details {
							p {
								color: $admin-color-white;
							}
						}
						.status {
							svg.done {
								fill: #00ff00;
							}
						}
						&:hover {
							background-color: #555;
						}
					}
					.main {
						p, label {
							color: #d3d3d3;
						}
					}
				}
			}
			.products {
				.product.labels {
					p {
						color: $admin-color-white;
					}
				}
				.product {
					.variation {
						input {
							background-color: $admin-color-black;
							border: 1px solid #777;
							color: $admin-color-white;
						}
						input:nth-child(1) {
							background-color: #393939;
						}
					}
				}
				.product.updates {
					border-top: 2px solid $admin-color-white;
					p {
						color: $admin-color-white;
					}
					input {
						background-color: $admin-color-black;
						border: 1px solid #777;
						color: $admin-color-white;
					}
					.input {
						.actions {
							span {
								background-color: #393939;
								color: #d3d3d3;
								&:hover {
									background-color: #111;
								}
							}
						}
					}
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> Queries
// =========================================/

.page-admin-dark-mode {
	.page-admin-main {
		.admin-vert-list {
			.avl-top {
				select, input {
					background-color: $admin-color-black;
					border: 1px solid #777;
					color: $admin-color-white;
				}
				label {
					color: #d3d3d3;
				}
			}
			.avl-post {
				background-color: #444;
				box-shadow: 1px 1px 1px #666;
				.query {
					.query-header {
						p {
							color: $admin-color-white !important;
						}
						i {
							color: #d3d3d3;
						}
					}
					.query-fields {
						p {
							color: #d3d3d3 !important;
							border-bottom: 1px solid #666;
						}
						.actions {
							label {
								color: #d3d3d3;
							}
						}
					}
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> Documentation
// =========================================/

.page-admin-dark-mode {
	.page-admin-main {
		.admin-documentation {
			.search {
				.tabs {
					p {
						color: #d3d3d3;
						&:hover {
							background-color: #292929;
						}
					}
					p.active {
						color: $admin-color-white;
						background-color: #444 !important;
						box-shadow: 1px 1px 1px #666;
					}
				}
				input {
					background-color: $admin-color-black;
					border: 1px solid #777;
					color: $admin-color-white;
				}
			}
			.posts {
				.no-results {
					p {
						color: #f2f2f2;
					}
				}
				.post {
					p.number {
						background-color: #111;
						text-shadow: 2px 2px 3px rgba(#444,0.5);
					}
					.body {
						background-color: #444;
						box-shadow: 1px 1px 1px #666;
						color: #d3d3d3;
						h2 {
							color: $admin-color-white;
						}
						a {
							color: $admin-color-primary;
						}
					}
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> File Share
// =========================================/

.page-admin-dark-mode {
	.page-admin-main {
		.admin-file-sharing {
			background-color: unset;
			.settings-header, .settings {
				background-color: #444;
				box-shadow: 1px 1px 1px #666;
				p {
					color: $admin-color-white;
				}
				svg {
					fill: #d3d3d3;
				}
				.section {
					border-bottom: 1px solid #666;
					h2 {
						color: $admin-color-white;
					}
					label {
						color: #d3d3d3;
					}
					select, input {
						background-color: $admin-color-black;
						border: 1px solid #777;
						color: $admin-color-white;
					}
				}
				.section:first-child {
					border-top: 1px solid #666;
				}
			}
			.file-search {
				background-color: $admin-color-black;
				input {
					background-color: $admin-color-black;
					border: 1px solid #777;
					color: $admin-color-white;
				}
			}
			.files {
				.container {
					background-color: #444;
					.file {
						p {
							color: #d3d3d3;
						}
						svg {
							fill: #d3d3d3;
							&:hover {
								fill: $admin-color-white;
							}
						}
					}
					.file.label {
						border-bottom: 1px solid #666;
						p {
							color: $admin-color-white;
						}
					}
					p.no-files {
						color: #d3d3d3;
					}
				}
			}
			.add-new {
				background-color: rgba(darken($admin-color-secondary-dark, 10%),.9);
				form {
					background-color: #444;
					label {
						color: #d3d3d3;
						input, select {
							background-color: $admin-color-black;
							border: 1px solid #777;
							color: $admin-color-white;
						}
					}
					.acbic-new {
						background-color: $admin-color-black;
						border: 2px dashed #666;
						p {
							color: #d3d3d3;
						}
					}
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> Config
// =========================================/

.page-admin-dark-mode {
	.page-admin-main {
		.admin-config {
			.block {
				background-color: #444;
				box-shadow: 1px 1px 1px #666;
				h1 {
					color: $admin-color-white;
				}
				p {
					color: #d3d3d3;
				}
			}
			.client-details {
				background-color: #444;
				label {
					color: #d3d3d3;
				}
				input {
					background-color: $admin-color-black;
					border: 1px solid #777;
					color: $admin-color-white;
				}
			}
			.buttons-bottom {
				background-color: $admin-color-black;
				padding: 20px 0px;
				.add-item {
					background-color: $admin-color-black;
					border: 2px dashed #666;
					p {
						color: #d3d3d3;
					}
					&:hover {
						border: 2px dashed $admin-color-primary;
					}
				}
			}
			.dashboard-blocks, .post-types {
				.item {
					background-color: #444;
					border-bottom: 2px solid $admin-color-black;
					label {
						color: #d3d3d3;
					}
					input, textarea {
						background-color: $admin-color-black;
						border: 1px solid #777;
						color: $admin-color-white;
					}
				}
			}
			.settings {
				.setting {
					background-color: #444;
					box-shadow: 1px 1px 1px #666;
					h2 {
						color: $admin-color-white;
					}
					textarea {
						background-color: $admin-color-black;
						border: 1px solid #777;
						color: $admin-color-white;
					}
				}
			}
			.buttons-bottom {
				div {
					p.plain {
						color: #d3d3d3;
					}
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> Admins
// =========================================/

.page-admin-dark-mode {
	.page-admin-main {
		.admin-admins {
			.settings {
				background-color: #444;
				box-shadow: 1px 1px 1px #666;
				color: $admin-color-white !important;
				input, select {
					background-color: $admin-color-black;
					border: 1px solid #777;
					color: $admin-color-white;
				}
				.new-admin {
					input[type=submit] {
						background-color: #555;
						border: 0px;
						cursor: default;
						&:hover {
							background-color: #555;
						}
					}
					input.active[type=submit] {
						cursor: pointer;
						background-color: $admin-color-secondary;
						&:hover {
							background-color: darken($admin-color-secondary,10%);
						}
					}
				}
				.new-access {
					input[type=submit] {
						margin: 0px;
						border: 2px solid $admin-color-white;
						padding: 6px;
						background: none;
						&:hover {
							background-color: $admin-color-white;
							color: $admin-color-secondary;
						}
					}
				}
				.levels {
					.dashboard-blocks {
						.dash {
							border: 2px solid #555;
							color: #888;
							i {
								color: #888;
							}
						}
						.dash.active {
							border: 2px solid $admin-color-secondary;
							color: #f2f2f2;
							i {
								color: $admin-color-secondary;
							}
						}
						p.save {
							background-color: #555;
							border: 0px;
							cursor: default;
							&:hover {
								background-color: #555;
							}
						}
						p.save.active {
							cursor: pointer;
							background-color: $admin-color-secondary;
							&:hover {
								background-color: darken($admin-color-secondary,10%);
							}
						}
					}
				}
			}
			.list {
				.user {
					h3 {
						color: $admin-color-white;
					}
					p {
						color: #d3d3d3;
					}
					.actions {
						p {
							color: $admin-color-white;
						}
						p.save {
							background-color: #888;
						}
						p.save.active {
							background-color: $admin-color-secondary;
						}
						select {
							background-color: $admin-color-black;
							border: 1px solid #777;
							color: $admin-color-white;
						}
					}
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> Comments
// =========================================/

.page-admin-dark-mode {
	.page-admin-main {
		.comments-list {
			.avl-tabs {
				p {
					background-color: #444;
					color: $admin-color-white !important;
					border-right: 1px solid #666 !important;
					border-bottom: 1px solid #666 !important;
				}
				p.avct-active {
					background-color: #666;
					border: 1px solid #666;
				}
			}
			.avl-post {
				h3 {
					color: $admin-color-white;
				}
				.comments-list {
					.comments-list-filter {
						select {
							background-color: $admin-color-black;
							border: 1px solid #777;
							color: $admin-color-white;
						}
					}
					.comment {
						p {
							color: #d3d3d3;
							border: 1px solid #666;
							svg {
								&:hover {
									fill: #777;
								}
							}
						}
					}
					.admin-comment {
						p:nth-child(1) {
							border-left: 4px solid $admin-color-primary;
						}
					}
				}
				p.comments-reply-empty {
					color: #d3d3d3;
					span {
						color: $admin-color-primary;
					}
				}
				.comments-new {
					select, textarea, input {
						background-color: $admin-color-black;
						border: 1px solid #777;
						color: $admin-color-white;
					}
					input[type=submit] {
						background-color: $admin-color-secondary;
						border: 0px;
						&:hover {
							background-color: darken($admin-color-secondary,10%);
						}
					}
				}
				.comments-reply {
					.comment {
						p {
							color: #d3d3d3;
							border: 1px solid #666;
						}
					}
					p.reply-text{
						color: $admin-color-white;
					}
					select, textarea, input {
						background-color: $admin-color-black;
						border: 1px solid #777;
						color: $admin-color-white;
					}
					input[type=submit] {
						background-color: $admin-color-secondary;
						border: 0px;
						&:hover {
							background-color: darken($admin-color-secondary,10%);
						}
					}
				}
				p.comments-reply-empty {
					color: #d3d3d3;
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> Bulk Upload
// =========================================/

.page-admin-dark-mode {
	.page-admin-main {
		.admin-bulk-upload {
			.instructions {
				background-color: #444;
				box-shadow: 0px 2px 2px #666;
				h2 {
					color: $admin-color-white;
				}
				p {
					color: #d3d3d3;
				}
			}
			.acbic-new {
				background-color: #222;
				border: 2px dashed #444;
				p {
					color: #d3d3d3;
				}
			}
			.downloads {
				p.block {
					background-color: #444;
					&:hover {
						border: 2px solid $admin-color-secondary;
					}
					span {
						color: $admin-color-white;
					}
					i {
						color: #a9a9a9;
					}
				}
			}
			p.download-links-message {
				color: #d3d3d3;
			}
			p.confirmation-message {
				color: $admin-color-primary;
			}
		}
	}
}

// =========================================/
// Dark Mode -> Shortcodes
// =========================================/

.page-admin-dark-mode {
	.page-admin-main {
		.admin-content {
			form {
				.acb-shortcodes {
					.acb-sc-list {
						.sc {
							p {
								color: #d3d3d3 !important;
							}
						}
					}
				}
			}
		}
	}
}

// =========================================/
// Dark Mode -> Temporary Page
// =========================================/

.page-admin-dark-mode {
	.temporary-page {
		p {
			color: $admin-color-white;
		}
	}
}
